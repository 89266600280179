import { useCallback, useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import I from 'immutable';
import List from "antd/lib/list";
import { useDispatch, useSelector } from 'react-redux';
import { Link, } from 'react-router-dom'
import { asImmutable, getCookie, setCookies } from '../helpers/utils';
import AppActionCreators from '../store/app/AppActionCreators';
import { Button, Card, Col, Collapse, Form, Image, Input, Modal, Row, Select, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import config from '../config/constants';
import moment from 'moment';




const DayFresh = () => {

    const mapState = useCallback(({ app }) => ({
        pincode: app.get('pincode'),
        // profile: app.getIn(['profile'], I.Map())
    }), [])

    const { pincode } = useSelector(mapState)
    document.title = "Day Fresh"


    const [error, setError] = useState()

    const [items, setItems] = useState(null)
    const [isPinModalVisible, setIsPinModalVisible] = useState(false);
    const [zipcodes, setZipcodes] = useState(I.List());
    const [zipType, setZipType] = useState();
    const [confirmLoading, setConfirmLoading] = useState();
    const [deliverySlots, setDeliverySlots] = useState(I.List());
    const [deliverySlot, setDeliverySlot] = useState();
    const [showDeliveryModal, setShowDeliveryModal] = useState();


    const dispatch = useDispatch()

    const fetchData = useCallback(() => {

        dispatch(AppActionCreators.requestFetchCategoryList(config.PRIMARY_MART, (res, e,) => {
            if (res) {
                let obj = {}
                let r = asImmutable(res)
                r.forEach(x => {
                    if (x.get('category_parent')) {
                        if (obj[x.get('category_parent')]) {
                            obj[x.get('category_parent')].push(x)
                        } else {
                            obj[x.get('category_parent')] = [x]
                        }
                    }
                })
                let t = r.filter(x => x.get('category_parent') == null)
                t = t.map(x => {
                    return x.set('data', asImmutable(obj[x.get('category_id')] ?? I.List()))
                })
                setItems(t)
            } else {
                setError(e.message ?? 'Error')
            }
        }))
    }, [])


    const getZipCodes = useCallback(() => {
        console.log(isPinModalVisible, !zipType);
        if (!zipType) {
            dispatch(AppActionCreators.requestFetchZipCodes(config.PRIMARY_MART, (res) => {
                if (res) {
                    let r = asImmutable(res)
                    setZipcodes(r.get('data'))
                    setZipType(r.get('type'))
                }
            }))
        }
    }, [isPinModalVisible])


    const setSlot = (item) => {
        setDeliverySlot(item)
        setCookies('delivery_time_id', item?.get('delivery_time_id'))
    }


    const getSlots = useCallback(() => {
        dispatch(AppActionCreators.requestFetchDeliverySlots(config.PRIMARY_MART, (res) => {
            if (res) {
                let data = asImmutable(res)
                setDeliverySlots(data)
                if (deliverySlot == null && !data.isEmpty()) {
                    let c = getCookie('delivery_time_id')
                    // array.reduce(function(total, currentValue, currentIndex, arr), initialValue)
                    let c1 = c ? data.reduce((a, b, i) => a.push(...b.getIn(['timeslots'])), I.List())
                        .filter(x => `${x.get('delivery_time_id')}` == `${c}`)?.first() : null

                    if (c1) {
                        setSlot(c1)
                    } else {
                        setSlot(data.getIn([0, 'timeslots', 0]))
                    }
                }
            }
        }))
    }, [deliverySlot])




    // const fetchZipCodesData = useCallback(() => {
    //     if (!getCookie('pincode') && config.IS_PINCODE_ENABLED) {
    //         // console.log('pin');
    //         setIsPinModalVisible(true);
    //     } else {
    //         //   setPinCode(getCookie('pincode'))
    //     }

    // }, [dispatch])

    useEffect(() => {
        // console.log(getCookie('pincode'));
        getSlots()
        if (getCookie('pincode')) {
            // fetchZipCodesData()
            fetchData()
        } else {
            setIsPinModalVisible(true)
            getZipCodes()
            // dispatch(AppActionCreators.setShowPinCode(true))
        }
    }, [pincode])


    // useEffect(() => {

    // }, [])



    return (<>
        <Content className="site-layout" >


            {error ?
                <div className='m-1 md:m-5 p-0 md:p-5 text-center flex justify-center items-center' style={{ minHeight: 300 }}>
                    <Title
                        type="error"
                        level={2}
                        className="text-center w-100 uppercase text-3xl lg:text-5xl leading-normal" style={{ maxWidth: 500 }}> {error} </Title>
                </div>
                :
                <div
                    className="container __content_shadow bg-white py-5 my-6  flex flex-col flex-wrap"
                    style={{ height: '100%' }}>

                    <Title
                        type="secondary"
                        level={2}
                        className="text-center w-100 mb-2">Day Fresh</Title>



                    <div className='container px-3 flex-col px-2 lg:px-5'>


                        {deliverySlot ? <div className='p-1 mb-3 text-right'>

                            <Typography.Text className='text-end' onClick={() => setShowDeliveryModal(true)}>
                                Delivery Slot :
                                <span className='pl-2 __text_primary font-bold'>
                                    {
                                        moment(deliverySlot.get('delivery_time_start'), "HH:mm:ss").format("hh:mm a") + ' - ' +
                                        moment(deliverySlot.get('delivery_time_end'), "HH:mm:ss").format("hh:mm a")
                                    }
                                </span>
                            </Typography.Text>


                        </div> : null}

                        {items?.isEmpty() ? <>

                            <div>

                                <Typography.Title level={4}
                                    className='p-3 sm:p-2 my-5 text-center'
                                >We apologize, we are not currently serving in this location at this point of time. Please try
                                    another pin-code.</Typography.Title>

                            </div>

                        </> :


                            <Collapse
                                bordered={false}
                                expandIconPosition="right"
                                defaultActiveKey={['1']}
                                className='__category_collapse __secondary0 overflow-hidden mb-4'
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                style={{
                                    background: 'white',
                                    border: '1px solid #e9e9e9',
                                    borderRadius: 10,
                                    padding: 0,
                                    margin: 0
                                }}

                            // items={getItems(panelStyle)}
                            >

                                {
                                    items?.map(x =>
                                        <Collapse.Panel
                                            key={x.get('category_id')}
                                            header={<div className='items-center flex' >
                                                <Image
                                                    preview={false}
                                                    src={x.get('category_image')}
                                                    width={64}
                                                    height={64}

                                                />
                                                <Link to={{
                                                    pathname: "/search",
                                                    search: "?type=dayfresh&title=" + encodeURIComponent(x.get('category_name')) + "&mode=category&id=" + x.get('category_id')

                                                }} >
                                                    <span className='ml-3'> {x.get('category_name')}</span>
                                                </Link>
                                            </div>}
                                        >

                                            <Row className=' flex-wrap mx-0 py-3 px-2 lg:px-5 ' gutter={[12, 12]} style={{ backgroundColor: '#f0f8eb' }}>

                                                {x.get('data', I.List()).isEmpty() ? <div className='pl-5 text-center'>No Items Found</div> : x.get('data')?.map(x1 => {
                                                    return <Col
                                                        key={x1.get('category_id')}>
                                                        <Link to={{
                                                            pathname: "/search",
                                                            search: "?type=dayfresh&title=" + encodeURIComponent(x1.get('category_name')) + "&mode=category&id=" + x1.get('category_id')

                                                        }}
                                                            className='flex'>
                                                            <Card
                                                                bordered
                                                                style={{ borderRadius: 20 }}
                                                                bodyStyle={{ padding: '5px 15px' }}
                                                            >
                                                                {x1.get('category_name')}
                                                            </Card>
                                                        </Link>
                                                    </Col>
                                                })}
                                            </Row>
                                        </Collapse.Panel>)
                                }
                            </Collapse>
                        }
                    </div>
                </div>
            }

            <Modal
                title={null} footer={null}
                visible={isPinModalVisible && zipType}
                destroyOnClose={true}
                onCancel={() => {
                    if (getCookie('pincode')) {
                        setIsPinModalVisible(false)
                    }
                }}>
                <Form
                    onFinish={(values) => {
                        // setIsPinModalVisible(false);
                        if (values.pincode) {
                            setCookies("pincode", values.pincode);
                            setIsPinModalVisible(false)
                            fetchData()
                            dispatch(AppActionCreators.setPinCode(values.pincode))
                        }
                        // setPinCode(values.pincode)

                    }}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    onCancel={null}
                >

                    {
                        zipType ? (zipType == "10" ?
                            <Form.Item
                                name="pincode"
                                label="Please enter your pincode"
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                rules={[{ required: true, message: 'Please input your pincode!' }, {
                                    message: "Pincode must be digits.",
                                    pattern: new RegExp(/^[0-9]+$/)
                                }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                            >
                                <Select placeholder="Please select your Pincode">
                                    {zipcodes.map((zipcodes, index) => {
                                        return <Select.Option key={index} value={zipcodes.get('pincode')}>{zipcodes.get('pincode')}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item> :
                            <Form.Item
                                name="pincode"
                                label="Please enter your pincode"
                                rules={[{ required: true, message: 'Please input your pincode!' }, {
                                    message: "Pincode must be digits.",
                                    pattern: new RegExp(/^[0-9]+$/)
                                }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                            >
                                <Input
                                    tabIndex="1"
                                    type="text"
                                    className="pincodeInput"
                                    maxLength='6'
                                />

                            </Form.Item>
                        ) : null}

                    <Form.Item
                        wrapperCol={{ offset: 12, span: 12 }}
                        className="mb-2">
                        <Button tabIndex="2"
                            loading={confirmLoading}
                            type='primary' htmlType="submit" style={{ minWidth: 120 }}>
                            Set Pincode
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>





            <Modal
                title={
                    <Row justify="space-between" className='align-middle'>
                        <Col>
                            <Title type="success" className='text-white mb-0 align-middle font-normal' level={5} >Change Time Slot</Title>
                        </Col>
                    </Row>
                }
                footer={null}
                visible={showDeliveryModal}
                destroyOnClose={true}
                onCancel={() => {
                    setShowDeliveryModal()
                    // if (getCookie('pincode')) {
                    //     setIsPinModalVisible(false)
                    // }
                }}>

                {deliverySlots?.map((x, index) => {
                    return <div key={index}>
                        <Title level={5}>{x.get('delivery_method_name')}</Title>
                        {x.get('timeslots', I.List()).map((item) => {
                            return <div
                                key={item.get('delivery_time_id')}
                                className={`__addressBox cursor-pointer  mb-2 ${item.get('delivery_time_id') === deliverySlot?.get('delivery_time_id') ? 'selected' : ''}`}
                                onClick={() => {
                                    setSlot(item)
                                    setShowDeliveryModal()
                                    fetchData()
                                }}
                            >
                                <span className='__text_primary font-bold'>
                                    {
                                        moment(item.get('delivery_time_start'), "HH:mm:ss").format("hh:mm a") + ' - ' +
                                        moment(item.get('delivery_time_end'), "HH:mm:ss").format("hh:mm a")
                                    }
                                </span>
                            </div>
                        })}

                    </div>
                })}

                {/* {
                    addressList.map((item, index) => {
                        return <AddressModalItem key={index} {...{ item }} onSelected={onSelected} />
                    })
                } */}
            </Modal>
        </Content >
    </>)
}
export default DayFresh
import { Card, Button, Typography, Select } from 'antd';
import { PlusOutlined, MinusOutlined, DeleteFilled, HeartFilled, HeartOutlined } from '@ant-design/icons'
import I from 'immutable'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { asImmutable, urlGenerator } from '../../helpers/utils';


const { Title } = Typography;
const { Option } = Select;

const ProductCard = (props) => {
    // var freshBatch = props.freshBatch ?? I.Map()
    var [price, setPrice] = useState()//props.freshBatch ? freshBatch.getIn(['batch_offer_price'], null) ?? freshBatch.getIn(['batch_special_price'], null) ?? freshBatch.getIn(['batch_sale_price'], null):null)
    var variants = props.variants ?? I.List()
    // var discount = 0;
    // if (freshBatch.get('batch_offer_price') ?? freshBatch.get('batch_special_price') != null) {
    //     discount = freshBatch.get('batch_offer_price') ?? freshBatch.get('batch_special_price') - freshBatch.get('batch_sale_price')
    // }
    // var discountRate = Math.trunc((discount / freshBatch.get('batch_sale_price')) * 100)

    const mapState = useCallback(({ app }) => ({
        cart: app.getIn(['cart'], I.Map),
        // cart: app.getIn(['cart', 'cart_items'], I.List()),
        // wish: app.getIn(['wish', 'data'], I.List()),
        wish: app.getIn(['wish'], I.List()),
        isGuest: app.getIn(['profile', 'isGuest'], true),
    }), [])

    const { cart, wish, isGuest } = useSelector(mapState)

    const [cartItem, setCartItem] = useState()
    const [wishItem, setWishItem] = useState()
    const [variant, setVariant] = useState(props.variant ?? (!variants.isEmpty() ? variants.get(0) : props.variant ?? I.Map()))
    const [discountRate, setDiscountRate] = useState()

    useEffect(() => {
        let batch = variant.get('fresh_batch') || variant.get('fresh_batches');

        // if (!cart.error_code && batch) {
        //     let q = cart.filter(x => x.get('cart_item_batch_id') === batch.get('batch_id'))
        //     if (!q.isEmpty()) {
        //         setCartItem(q.first())
        //     } else {
        // setCartItem()
        // }
        // }
        // if (!variants.isEmpty()) {
        //     setVariant(variants.get(0))
        // }


        if (variant) {
            let v = cart.get(variant.get('variant_id') + '_' + variant.getIn(['fresh_batch', 'batch_id'], 0))
         
            setCartItem(v ? asImmutable({ 'cart_item_quantity': v }) : null)
            setPrice(batch ? (batch.getIn(['batch_offer_price'], null) ?? batch.getIn(['batch_special_price'], null)) : '')
        }
    }, [cart, variant])


    useEffect(() => {
        if ((variant)) {
            // let q = wish.filter(x => x.get('variant_id') == variant.get('variant_id'))

            let q = wish.filter(x => x === variant.get('variant_id'))

            if (!q.isEmpty()) {
                setWishItem(true)
            } else {
                setWishItem()
            }
        }
    }, [wish, variant])


    function handleChange(value) {
        let variantItem = variants.find((item, key) => item.get('variant_id') === value)
        setVariant(variantItem)
    }

    function preventClick(event) {
        event.preventDefault()
    }


    useEffect(() => {
        let batch = variant.get('fresh_batch') || variant.get('fresh_batches');
        setPrice(batch ? (batch.getIn(['batch_special_price'], null) ?? batch.getIn(['batch_offer_price'], null)) : '')
        var discount = 0;
        if (batch) {
            if (batch.getIn(['batch_special_price']) ?? batch.getIn(['batch_offer_price']) != null) {
                discount = batch.getIn(['batch_sale_price']) - (batch.getIn(['batch_special_price']) ?? batch.getIn(['batch_offer_price']))
            }
        }
        setDiscountRate(batch ? (Math.trunc((discount / batch.getIn(['batch_sale_price'])) * 100)) : '')

    }, [variant])


    const [searchParams] = useSearchParams();

    return (
        <Card
            bordered={false} hoverable
            className={`card-shadow-sm __product_card ${props.centerMode ? 'mx-auto' : ''}`}
            bodyStyle={{ padding: '0 0 5px 0', overflow: 'hidden' }} >

            <Link className='h-100' style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column' }} to={{
                pathname: "/variantproducts/" + variant.get('variant_id') + (props.productName ? urlGenerator(props.productName) : ''),
            }}>


                {variant?.get('images') != null && variant?.get('images')?.size > 0 ? <img className="__product_image"
                    src={variant?.getIn(['images', 0, 'variant_images_name'])} alt="" /> : <img className="__product_image" src={props.productImage} alt="" />}

                {isGuest ? null :

                    <Link to={`?${searchParams}`}
                        onClick={(e) => {
                            e.preventDefault()
                            if (wishItem) {
                                props.updateWishlist(variant.get('variant_id'), true)
                            } else {
                                props.updateWishlist(variant.get('variant_id'), false)
                            }
                        }}>
                        <div className="__product_wish">
                            {wishItem ? <HeartFilled style={{ color: `var(--primary-color)` }}
                            // onClick={(e) => {
                            //     e.preventDefault()
                            //     props.updateWishlist(variant.get('variant_id'), true)
                            // }}
                            /> : <HeartOutlined
                            //  onClick={(e) => {
                            //     e.preventDefault()
                            //     props.updateWishlist(variant.get('variant_id'), false)
                            // }} 
                            />}
                        </div>
                    </Link>
                }

                {
                    discountRate > 0 ?
                        <div className='discount-banner'>
                            <Title style={{ marginLeft: 5, color: 'white' }} level={3}>{discountRate}%</Title>
                        </div> : null
                }
                <div className="__title_div flex flex-col flex-1" >

                    <Title style={{ marginTop: '5px', }} className="line-clamp-2 mb-0" level={5}>{props.productName}</Title>


                    <div className="" style={{ alignItems: 'center' }} >
                        {variants.size > 1 ?
                            <Select className='__dropdown flex items-center justify-center'
                                defaultValue={variant.get('variant_id')}
                                onChange={handleChange}
                                onClick={preventClick}>
                                {
                                    variants.map((item, index) => {
                                        return (<Option key={item.get('variant_id')} value={item.get('variant_id')}>
                                            <span>{item.get('variant_name')}</span>
                                            {/* <span>{item.get('variant_quantity')}</span><span>{item.getIn(['unit', 'unit_name']) ?? item.get('unit_name')}</span> */}
                                        </Option>)
                                    })
                                }
                            </Select >
                            : <div className='__variant_text line-clamp-1' >
                                {variants?.getIn([0, 'variant_name'])}
                            </div>}

                    </div>

                    <Title className='mb-3 align-middle' style={{ marginBottom: 0 }} level={4}>
                        {(variant.get('fresh_batch') || variant.get('fresh_batches')) ? `₹${price ?? (variant != null ? variant.getIn(['fresh_batch', 'batch_sale_price'], null) || variant.getIn(['fresh_batches', 'batch_sale_price'], null) : '')}` :
                            <span className=' text-sm text-slate-500'>Not Available </span>}
                        {price ? <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }} className='px-3 text-sm text-slate-500'>{`₹${(variant.getIn(['fresh_batch', 'batch_sale_price'], null) || variant.getIn(['fresh_batches', 'batch_sale_price'], null))}`}</span> : null}
                    </Title>
                    <div className="flex flex-1">

                    </div>
                    {cartItem ? <div className="flex justify-between " >
                        <Button block className='card-shadow-xs __add_to_cart_button reverse minus'
                            icon={cartItem.getIn(['cart_item_quantity'], 0) === 1 ? <DeleteFilled /> : <MinusOutlined />}
                            onClick={(e) => {
                                e.preventDefault()
                                props.add2Cart(
                                    (variant.get('fresh_batch') || variant.get('fresh_batches')).get('batch_id'),
                                    cartItem.getIn(['cart_item_quantity'], 0) > 0 ? cartItem.getIn(['cart_item_quantity'], 0) - 1 : 0,
                                    // cartItem.getIn(['cart_item_id'], 0)
                                )

                            }} />
                        <span className='flex justify-middle font-bold ' style={{ alignItems: 'center' }}>
                            {cartItem.getIn(['cart_item_quantity'], 0)}
                        </span>

                        <Button block className='card-shadow-xs __add_to_cart_button reverse'
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                                e.preventDefault()
                                props.add2Cart((variant.get('fresh_batch') || variant.get('fresh_batches')).get('batch_id'), cartItem.getIn(['cart_item_quantity'], 0) + 1)
                            }} />

                    </div>
                        :
                        (variant.get('fresh_batch') || variant.get('fresh_batches')) ?
                            <Button block className='card-shadow-xs __add_to_cart_button' onClick={(e) => {
                                e.preventDefault()
                                props.add2Cart((variant.get('fresh_batch') || variant.get('fresh_batches')).get('batch_id'), 1)
                            }}>Add to Cart</Button>
                            : <Button disabled block className='card-shadow-xs __add_to_cart_button'>Out of Stock</Button>
                    }



                </div >

            </Link>

        </Card >

    );
}

export default ProductCard
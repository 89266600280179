import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Tooltip, Col, Row, Modal, Typography, Button, Drawer } from 'antd';
import { PlusOutlined, AndroidFilled, AppleFilled, WhatsAppOutlined, CustomerServiceFilled, EnvironmentTwoTone } from '@ant-design/icons';
import { useLocation } from "react-router"
import I from 'immutable';
import Searchbar from '../widgets/Searchbar';
import LoginPanel from './LoginPanel';
import { Link } from 'react-router-dom';
import config from '../config/constants';
import CartDropDown from './CartDropDown';
import AppActionCreators from '../store/app/AppActionCreators';
import { getCookie } from '../helpers/utils';

const { Header } = Layout;


const Navbar = (props) => {

    const mapState = useCallback(({ app }) => ({
        pincode: app.get('pincode'),
        profile: app.getIn(['profile'], I.Map())
    }), [])

    const { setIsPinModalVisible, loginPanelVisible, setLoginPanelVisible } = props

    const { pincode, profile } = useSelector(mapState)

    const [isAddressVisible, setIsAddressVisible] = useState()
    const [pinCodeState, setPinCodeState] = useState(pincode)

    useEffect(() => {
        if (pincode) {
            setPinCodeState(pincode)
        } else {
            setPinCodeState(getCookie('pincode'))
        }
    }, [pincode])


    return (
        <>


            <Header className="header flex flex-col p-0" style={{ position: 'sticky', top: 0, width: '100%', lineHeight: '40px' }} >
                <Row className='bg-slate-100 w-100 flex  items-center justify-between'>
                    <Col xs={5} sm={5} md={12} lg={12} className='flex justify-center md:justify-start'>
                        <Row className='flex items-center md:px-5 lg:px-5' >
                            {/* <div className="p-1 flex items-center">Download App</div> */}
                            <div className="p-1"><a className='flex' href={config.APP_STORE} target={'_blank'} rel="noreferrer"><AppleFilled style={{ fontSize: '20px' }} /></a></div>
                            <div className="p-1"><a className='flex' href={config.PLAY_STORE} target={'_blank'} rel="noreferrer"><AndroidFilled style={{ fontSize: '20px' }} /></a></div>
                        </Row>
                    </Col>
                    <Col xs={19} sm={19} md={12} lg={12} className='flex justify-end'>
                        <Row className='flex items-center px-3 md:px-5 lg:px-5' >
                            <Col className='p1-1 py-2'>
                                <a href={config.WHATSAPP} style={{ display: 'flex', alignItems: 'center' }}>
                                    <WhatsAppOutlined className='p-1' />
                                    <span className='text-xs lg:text-base'>+91 8590151204</span>
                                </a>
                            </Col>
                            <Col className='pl-2'>
                                <a href={config.CUSTOMER_SERVICE} style={{ display: 'flex', alignItems: 'center' }}>
                                    <CustomerServiceFilled className='p-1' />
                                    <span className='text-xs lg:text-base'>  +91 8590151204
                                    </span>
                                </a>
                            </Col>
                            {/* <Col className='p-1'>
                                <a href={config.CHAT} style={{ display: 'flex', alignItems: 'center' }}><WechatOutlined className='p-1' /> Chat</a>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>

                <Row className="header_row w-100 flex items-center justify-between px-1">
                    <Col xs={16} sm={8} md={6} lg={5} className='lg:pl-5 md:pl-5 order-first xs:order-1 flex items-center' >
                        <div className="logo" >
                            <Link to='/'>
                                <img alt="logo" src={config.url.IMAGE_BASE_URL + 'client/logo.png'} />
                            </Link>
                        </div>

                        <a href='/dayfresh'>
                            <Button
                                shape='round'
                                className="__btnStyle __btn_secondary"
                            // onClick={() => setModalFresh(true)}
                            // icon={<HeartFilled style={{ fontSize: '20px' }} />}
                            >
                                {/* <img
                                    src={config.url.IMAGE_BASE_URL + 'app/day_fresh.jpeg'}
                                /> */}
                                
                                Day Fresh
                            </Button>
                        </a>
                    </Col>

                    <Col xs={8} sm={16} md={10} lg={9}
                        className="flex flex-col justify-center xs:order-2 sm:order-2 md:order-3 lg:order-3">
                        <Row style={{ alignItems: "center" }} className="flex  justify-end lg:justify-center">
                            <CartDropDown />
                            <Tooltip title="Login">
                                <LoginPanel {...{ setIsPinModalVisible, loginPanelVisible, setLoginPanelVisible }} />
                            </Tooltip>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={8} lg={10} className=' sm:order-3 py-1 md:order-2'>
                        <Row className='w-100 flex flex-nowrap px-2 lg:items-center lg:justify-center flex-col lg:flex-row'>
                            <Col className='py-1'>
                                <Row className='flex flex-nowrap items-center'>
                                    <EnvironmentTwoTone className='px-1' style={{ fontSize: '20px' }} twoToneColor="#1C9F47" />

                                    
                                    {profile && !profile.getIn(['isGuest'], true) ?
                                        <span className="__hover __anchor text-xs line-clamp-1" onClick={() => setIsAddressVisible(true)}>
                                            {profile.getIn(['address', 'address_building']) ?
                                                <>{profile.getIn(['address', 'address_building'])},<br /></> : null}
                                            {/* {profile.getIn(['address', 'address_zip'], '') == '111111' ? null : profile.getIn(['address', 'address_zip'], '')} */}
                                        </span> :
                                        <span className="__hover __anchor" style={{ minWidth: 60 }} onClick={() => props.setIsPinModalVisible(true)}>{profile ? `${pincode ?? ''} ` : ''}</span>}
                                </Row>
                            </Col>

                            <Col className='w-100 flex items-center relative ' style={{ maxWidth: 600 }}>
                                <Searchbar />
                            </Col>
                        </Row>

                    </Col>

                </Row>
                {isAddressVisible ? <ModalAddressChange {...{ isAddressVisible, setIsAddressVisible }} /> : null}

                {/* <Modal
                    title="Vertically centered modal dialog"
                    centered
                    width={'90%'}
                    height={'90%'}
                    visible={modalFresh}
                    onOk={() => setModalFresh(false)}
                    onCancel={() => setModalFresh(false)}
                    bodyStyle={{ minHeight: 500 }}
                    footer={null}
                >
                    <p>some contents...</p>
                    <p>some contents...</p>
                    <p>some contents...</p>
                </Modal>  */}

                {/* <Drawer
                    visible={modalFresh}
                    onClose={() => setModalFresh(false)}
                    width="100VW"
                    placement={'left'}
                    // closable={false}
                    bodyStyle={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div className='flex items-center justify-center'>

                        The content goes here
                    </div>
                </Drawer> */}

            </Header >
        </>
    );
}
export default Navbar;




export const ModalAddressChange = (props) => {

    const mapState = useCallback(({ app }) => ({
        addressList: app.getIn(['address'], I.List())
    }), [])

    const { addressList } = useSelector(mapState)

    const { isAddressVisible, setIsAddressVisible } = props

    const hideModal = () => {
        setIsAddressVisible(false);
    };

    const dispatch = useDispatch()

    const { Title } = Typography;

    let location = useLocation()


    useEffect(() => {
        if (addressList.isEmpty()) {
            dispatch(AppActionCreators.requestFetchAddressList())
        }
    }, [dispatch])

    const onSelected = (address) => {

        const item = { ...address.toJS() }
        if (address.get('address_zip')) {
            item.is_default = 10
            dispatch(AppActionCreators.requestCreateOrUpdateAddress1(item))
            setIsAddressVisible()
        } else {

        }

    }


    return (
        <>
            <Modal title={
                <Row justify="space-between" className='align-middle'>
                    <Col>
                        <Title type="success" className='text-white mb-0 align-middle font-normal' level={5} >Change Delivery Address</Title>
                    </Col>
                    <Col className='w-screen lg:w-auto text-end flex justify-end'>
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== 'add'
                            && location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== 'edit' ?
                            <Link to='/account/address/add'>
                                <Row
                                    align="center"
                                    style={{ marginBlock: 'auto' }}
                                    className="mx-8 rounded hover:shadow  group  hover:bg-green-500 transition ease-in-out duration-150 delay-150"
                                    onClick={hideModal}>

                                    <PlusOutlined className="my-auto px-1 group-hover:text-green-100 text-white transition ease-in-out duration-300 delay-150" />
                                    <Title className="mb-0 px-1 group-hover:text-green-100 text-white transition ease-in-out duration-300 delay-150"
                                        type="success" level={5}
                                        style={{ fontWeight: 'normal' }}>Add Address</Title>
                                </Row>

                            </Link> : null

                        }
                    </Col>
                </Row>

            }
                footer={null}
                visible={isAddressVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setIsAddressVisible()
                    // if (getCookie('pincode')) {
                    //     setIsPinModalVisible(false)
                    // }
                }}>
                <Title level={5}>Choose a delivery Address</Title>
                {
                    addressList.map((item, index) => {
                        return <AddressModalItem key={index} {...{ item }} onSelected={onSelected} />
                    })
                }
            </Modal>
        </>
    )
}

const AddressModalItem = (props) => {

    const { item } = props

    return <div className={`__addressBox  ${item.get('is_default') === 10 ? 'selected' : ''}`} onClick={() => item.get('is_default') !== 10 ? props.onSelected(item) : null}>
        <span><b>{item.get('address_name')}</b><br />
            {[
                item.get('address_building'),
                item.get('address_city'),
                item.get('address_road'),
                item.get('address_zip')].filter(Boolean).join(", ")
            }
            {item.get('is_default') === 10 ? <b className="__text_primary"><br />Default</b> : ''}</span>

    </div>
}
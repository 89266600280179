import I from 'immutable'
import Cookies from 'universal-cookie';
// import MobileDetect from 'mobile-detect'

// const MD = new MobileDetect(window.navigator.userAgent)
// export const isMobile = () => MD.mobile()

export const setCookies = (name, value, expiry) => {
  const cookies = new Cookies();
  cookies.set(name, value, { maxAge: 1000000, path: '/' })
}

export const clearCookie = (name) => {
  const cookies = new Cookies();
  cookies.remove(name)
}

export const clearCookies = () => {
  const cookies = new Cookies();
  console.log('remove token')
  // cookies.set('token', '')
  cookies.remove('token')
  let c = cookies.getAll()
  Object.keys(c).forEach(element => {
    console.log(element)
    cookies.remove(element)
  })
  // Cookies.remove('token', { path: '/' })
}
export const getCookie = (cookieName) => {
  const cookies = new Cookies();
  // if (cookieName == "pincode") {
  //   return ''
  //   //   return '111111'
  // }
  return cookies.get(cookieName) ?? ''
}

export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token')
}

export const setToken = (token) => {
  const cookies = new Cookies();
  return cookies.set('token')
}

export const getUserID = () => {
  const cookies = new Cookies();
  return cookies.get('delegate_id')
}

export const isFunction = fn => typeof fn === 'function'

export const runLater = (fn, t = 100) => {
  setTimeout(() => {
    fn()
  }, t)
}

export const asImmutable = data => (I.isImmutable(data) ? data : I.fromJS(data))

export const createAction = (type, data) => Object.assign({ type }, data ? { payload: { ...data } } : {})



export const updateListItem = (list, data, insert = false, id = 'id') => {
  let items = list
  let index = -1
  let existingItem = items.find((item, key) => {
    if (item.get(id).toString() === data.get(id).toString()) {
      index = key
      return true
    }
    return false
  })

  if (existingItem) {
    items = items.update(index, I.Map(), value =>
      value.mergeWith((oldVal, newVal) => {
        return newVal
      }, data)
    )
  } else {
    if (insert === true) {
      items = items.unshift(data)
    } else {
      console.log("Item not found")
    }

  }
  return items
}


export const getItemByKeyValue = (list, key, value) => {
  if (!list || !I.isImmutable(list)) {
    return null
  }
  return list.find(item => item.get(key) === value)
}

export const insertIntoIndex = (list, itemsToInsert, offset, key) => {
  let result = list

  itemsToInsert.forEach((item, idx) => {
    if (offset >= 0) {
      result = result.set(offset + idx, I.fromJS(item))
    } else {
      let ci = result.findIndex(o => o.get(key) === item[key])
      if (ci >= 0) {
        result = result.set(ci, result.get(ci).mergeDeep(I.fromJS(item)))
      } else {
        result = result.push(I.fromJS(item))
      }
    }
  })
  return result
}

export const trimPath = path => {
  if (path[path.length - 1] === '/') {
    return path.substr(0, path.length - 1)
  }
  return path
}

export const ellipsisString = (str, len = 20) => {
  return str.length > len ? `${str.slice(0, len)}...` : str
}

/**
 * @param {I.List|Array} list
 * @param {String|Number|I.Map|Object} item
 * @param {String='id'} key
 */
export const removeListItem = (list, item, key = 'id') => {
  let index = -1
  let value = item
  if (I.isImmutable(item)) {
    value = item.get(key)
  } else if (typeof item === 'object') {
    value = item['value']
  }

  if (I.List.isList(list)) {
    index = list.findIndex(li => li.get(key) === value)
  } else {
    index = list.findIndex(li => li[key] === value)
  }
  if (index >= 0) {
    return list.delete(index)
  }
  return list
}

export const getBase64ImageData = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getUploadObjFromUrl = url => {
  if (!url) return null
  const uid = new Date().getTime()
  return {
    id: uid,
    uid: uid,
    url: url
  }
}

export const errorsToForm = (e) => {
  const errors = asImmutable(e || {})
  if (errors.size > 0) {
    let c = Object.keys(errors.toJS()).map(x => {
      // console.log(x, errors.get(x, []).toJS());
      return ({ name: x, errors: errors.get(x, []).toJS() })
    })
    // console.log(c);
    return c
  }
  return []
}


export const urlGenerator = (key) => {
  return "-" + key.toLowerCase().replace(/[^\w-]+/g, '-');
}